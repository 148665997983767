<template>
  <div class="content">
    <div class="top">
      <a-button @click="search">更新</a-button>
      <a-button
          class="ml-10"
          type="primary"
          @click="handleAddTemplate"
          icon="plus"
      >新增模版</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
<!--  新增模版0  -->
    <EditorTemplatePopup ref="editorTemplatePopupEl" @success="getList"/>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/shopManage/messageTemplateList/_components/TableList/index.vue"
import {getMessageTemplateListApi} from "@/views/cmsPage/shopManage/messageTemplateList/_apis"
import EditorTemplatePopup
  from "@/views/cmsPage/shopManage/messageTemplateList/_components/EditorTemplatePopup/index.vue"

export default {
  components: {
    TableList, EditorTemplatePopup
  },
  data() {
    return {
      data: [],
      tempData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        title: undefined
      },
    };
  },
  async mounted() {
    const { title } = this.$route.query;
    this.$set(this.params, "title", title);
    await this.getList()
  },
  methods: {
    /** 新增模版 */
    handleAddTemplate() {
      this.$refs.editorTemplatePopupEl.show()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      this.params.title = undefined
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getMessageTemplateListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
