
/** 消息通知模版列表 */
export const messageTemplateTableColumns = [
    { title: "模版标题", align: "center", scopedSlots: {customRender: 'itemTemplateNameSlot'}, width: 150 },
    { title: "模版类型", align: "center", scopedSlots: {customRender: 'itemTemplateTypeSlot'}, width: 250 },
    { title: "推送对象", align: "center", scopedSlots: { customRender: "itemTemplatePushUserSlot" }, width: 150 },
    { title: "弹窗显示", align: "center", scopedSlots: { customRender: "itemIsPopupStatusSlot" }, width: 100},
    { title: "简介", align: "center", scopedSlots: { customRender: "itemSContentSimpleSlot" }, width: 250},
    { title: "操作时间", align: "center", scopedSlots: { customRender: 'itemCreateOrUpdateAboutSlot' }, width: 250 },
    { title: "发布状态", align: "center", scopedSlots: { customRender: 'itemPublishStatustSlot' }, width: 250 },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 100 },
]