var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 1000, title: _vm.popupTitle },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirmEditorTemplate },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b({}, "a-form-model", _vm.layout, false),
        [
          _c(
            "a-form-item",
            { attrs: { label: "模版标题" } },
            [
              _c("a-input", {
                staticClass: "w-600",
                attrs: { placeholder: "请输入模版标题" },
                model: {
                  value: _vm.params.title,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "title", $$v)
                  },
                  expression: "params.title"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "模版标题图片（可选）" } },
            [
              _c("UploadImage", {
                attrs: { list: _vm.titleImgList, maxCount: 1 },
                on: { change: _vm.handleChangeByTitleImg }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "模版简介（可选）" } },
            [
              _c("a-textarea", {
                staticClass: "w-500",
                attrs: {
                  type: "text",
                  "auto-size": { minRows: 2, maxRows: 4 },
                  maxLength: 300,
                  placeholder: "请填写简介（300）"
                },
                model: {
                  value: _vm.params.intro,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "intro", $$v)
                  },
                  expression: "params.intro"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "推送给谁" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-600",
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    placeholder: "请选择推送给谁"
                  },
                  model: {
                    value: _vm.params.pushObject,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "pushObject", $$v)
                    },
                    expression: "params.pushObject"
                  }
                },
                _vm._l(_vm.messageTemplatePushUserList, function(items) {
                  return _c(
                    "a-select-option",
                    { key: items.value, attrs: { value: items.value } },
                    [_vm._v(_vm._s(items.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "是否是弹窗显示" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-600",
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    placeholder: "请选择是否以弹窗的形式显示"
                  },
                  model: {
                    value: _vm.params.isPopUp,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "isPopUp", $$v)
                    },
                    expression: "params.isPopUp"
                  }
                },
                _vm._l(_vm.messageTemplateIsPopupTypeList, function(items) {
                  return _c(
                    "a-select-option",
                    { key: items.value, attrs: { value: items.value } },
                    [_vm._v(_vm._s(items.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "模版内容类型" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-600",
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    placeholder: "请选择模本内容的表现形式"
                  },
                  on: { change: _vm.handleChangeTemplateType },
                  model: {
                    value: _vm.params.informType,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "informType", $$v)
                    },
                    expression: "params.informType"
                  }
                },
                _vm._l(_vm.messageTemplateContentTypeList, function(items) {
                  return _c(
                    "a-select-option",
                    { key: items.value, attrs: { value: items.value } },
                    [_vm._v(_vm._s(items.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.params.informType === 3
            ? _c(
                "a-form-item",
                { attrs: { label: "链接地址" } },
                [
                  _c("a-input", {
                    staticClass: "w-100-w",
                    attrs: { placeholder: "请输入文本的链接地址" },
                    model: {
                      value: _vm.params.linkUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "linkUrl", $$v)
                      },
                      expression: "params.linkUrl"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.params.informType === 2
            ? _c(
                "a-form-item",
                { attrs: { label: "模版内容图片" } },
                [
                  _c("UploadImage", {
                    attrs: { list: _vm.contentImgList, maxCount: 1 },
                    on: { change: _vm.handleChangeByContentImg }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.params.informType === 1
            ? _c(
                "a-form-item",
                { attrs: { label: "模版内容" } },
                [
                  _c("WangEditor", {
                    attrs: { option: _vm.option },
                    model: {
                      value: _vm.params.content,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "content", $$v)
                      },
                      expression: "params.content"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.params.id
            ? _c(
                "a-form-item",
                { attrs: { label: "模版状态" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "radioGroup" },
                      model: {
                        value: _vm.params.status,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "status", $$v)
                        },
                        expression: "params.status"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("草稿")]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("发布")])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }