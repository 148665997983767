var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-button", { on: { click: _vm.search } }, [_vm._v("更新")]),
          _c(
            "a-button",
            {
              staticClass: "ml-10",
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.handleAddTemplate }
            },
            [_vm._v("新增模版")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("TableList", {
            attrs: { list: _vm.data, pagination: _vm.pagination },
            on: { changePage: _vm.handleChangePage, success: _vm.getList }
          })
        ],
        1
      ),
      _c("EditorTemplatePopup", {
        ref: "editorTemplatePopupEl",
        on: { success: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }