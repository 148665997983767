<template>
  <a-modal
      v-model="showPopup"
      :width="1000"
      :title="popupTitle"
      @cancel="handleCancel"
      @ok="handleConfirmEditorTemplate"
  >
    <a-form-model v-bind="layout">
      <a-form-item label="模版标题">
        <a-input
            v-model="params.title"
            class="w-600"
            placeholder='请输入模版标题'
        ></a-input>
      </a-form-item>
      <a-form-item label="模版标题图片（可选）">
        <UploadImage
            :list="titleImgList"
            :maxCount="1"
            @change="handleChangeByTitleImg"
        ></UploadImage>
      </a-form-item>
      <a-form-item label="模版简介（可选）">
        <a-textarea
            type="text"
            class="w-500"
            v-model="params.intro"
            :auto-size="{ minRows: 2, maxRows: 4 }"
            :maxLength="300"
            placeholder="请填写简介（300）"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="推送给谁">
        <a-select
            :filter-option="untils.filterOption"
            allowClear
            class="w-600"
            v-model="params.pushObject"
            placeholder="请选择推送给谁"
        >
          <a-select-option
              v-for="items of messageTemplatePushUserList"
              :key="items.value"
              :value="items.value"
          >{{ items.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="是否是弹窗显示">
        <a-select
            :filter-option="untils.filterOption"
            allowClear
            class="w-600"
            v-model="params.isPopUp"
            placeholder="请选择是否以弹窗的形式显示"
        >
          <a-select-option
              v-for="items of messageTemplateIsPopupTypeList"
              :key="items.value"
              :value="items.value"
          >{{ items.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="模版内容类型">
        <a-select
            :filter-option="untils.filterOption"
            allowClear
            class="w-600"
            v-model="params.informType"
            placeholder="请选择模本内容的表现形式"
            @change="handleChangeTemplateType"
        >
          <a-select-option
              v-for="items of messageTemplateContentTypeList"
              :key="items.value"
              :value="items.value"
          >{{ items.name }}</a-select-option>
        </a-select>
      </a-form-item>
<!--  链接时    -->
      <a-form-item label="链接地址" v-if="params.informType === 3">
        <a-input
            v-model="params.linkUrl"
            class="w-100-w"
            placeholder='请输入文本的链接地址'
        ></a-input>
      </a-form-item>
<!--  图片时    -->
      <a-form-item label="模版内容图片" v-if="params.informType === 2">
        <UploadImage
            :list="contentImgList"
            :maxCount="1"
            @change="handleChangeByContentImg"
        ></UploadImage>
      </a-form-item>
<!--  富文本时    -->
      <a-form-item label="模版内容" v-if="params.informType === 1">
        <WangEditor :option="option" v-model="params.content"/>
      </a-form-item>
<!--  是否需要发布    -->
      <a-form-item label="模版状态" v-if="params.id">
        <a-radio-group
            name="radioGroup"
            v-model="params.status"
        >
          <a-radio :value="0">草稿</a-radio>
          <a-radio :value="1">发布</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {editorMessageTemplateApi} from "@/views/cmsPage/shopManage/messageTemplateList/_apis"
import untils from "@/untils"
import {
} from "@/views/cmsPage/shopManage/messageTemplateList/_data"
import {mapState} from "vuex"
import UploadImage from "@/components/UploadImage/index.vue"
import WangEditor from "@/components/WangEditor/index.vue"
import utils from "@/untils"
import {
  messageTemplateContentTypeList,
  messageTemplateIsPopupTypeList,
  messageTemplatePushUserList
} from "@/views/cmsPage/shopManage/_data"

export default {
  components: {UploadImage, WangEditor},
  data() {
    return {
      untils,
      option: {
        height: 500
      },
      contentImgList: [], // 内容图片
      titleImgList: [], // 标题图片
      popupTitle: '新增模版',
      showPopup: false,
      messageTemplateContentTypeList, // 模版类型
      messageTemplatePushUserList, // 推送对象，
      messageTemplateIsPopupTypeList, // 是否是弹窗显示
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        id: undefined,
        title: undefined,
        titlePicUrl: undefined,
        content: '',
        pictureUrl: undefined,
        linkUrl: undefined,
        informType: undefined, // 模版类型
        pushObject: undefined, // 推送对象
        isPopUp: undefined,
        intro: undefined, // 简介
        status: 0
      },
      editorType: 'add',
      editorApiUrl: {
        add: '/dq_admin/platformPushTemplate/add',
        edit: '/dq_admin/platformPushTemplate/update'
      }
    };
  },
  computed: {
    ...mapState(['ip'])
  },
  methods: {
    /** 改变模版类型 */
    handleChangeTemplateType(e) {
      this.params.content = undefined
      this.params.pictureUrl = undefined
      this.params.linkUrl = undefined
    },
    show(data) {
      if (data && data.id) {
        this.editorType = 'edit'
        this.popupTitle = '编辑更新模版';
        this.setData(data)
      }
      this.showPopup = true
    },
    /** 设置回显 */
    setData(data) {
      Object.assign(this.params, data)
      if (data.titlePicUrl) {
        this.titleImgList = [
          {
            uid: utils.randomString(5),
            name: "image",
            status: "done",
            url: data.titlePicUrl,
          }
        ]
      }
      if (data.pictureUrl) {
        this.contentImgList = [
            {
            uid: utils.randomString(5),
            name: "image",
            status: "done",
            url: data.pictureUrl,
          }
        ]
      }
    },
    /** 确定 */
    async handleConfirmEditorTemplate() {
      if (!this.params.title) return this.$message.warn('模版标题必须上传')
      if (!this.params.intro && this.titleImgList.length < 1) return this.$message.warn('模版标题图片，模版简介必须填写一个')
      if (!this.params.pushObject) return this.$message.warn('推送对象不能为空')
      if (![1, 0].includes(this.params.isPopUp)) return this.$message.warn('是否以弹窗显示选择不能为空')
      if (!this.params.informType) return this.$message.warn('内容类型不能空')
      if (this.params.informType === 1 && this.params.content === '<p><br></p>') return this.$message.warn('富文本内容不能空')
      if (this.params.informType === 2 && this.contentImgList.length < 1) return this.$message.warn('内容图片必须上传')
      if (this.params.informType === 3 && !this.params.linkUrl) return this.$message.warn('内容链接路径不能空')

      if (this.titleImgList.length) {
        this.params.titlePicUrl = this.titleImgList[0].url
      }
      if (this.contentImgList.length) {
        this.params.pictureUrl = this.contentImgList[0].url
      }
      if (this.params.informType === 1) {
        this.params.pictureUrl = undefined
        this.params.linkUrl = undefined
      } else if (this.params.informType === 2) {
        this.params.content = undefined
        this.params.linkUrl = undefined
      } else if (this.params.informType === 3) {
        this.params.content = undefined
        this.params.pictureUrl = undefined
      }

      this.$loading.show()
      const res = await editorMessageTemplateApi(this.editorApiUrl[this.editorType], this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message)
      this.showPopup = false
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.popupTitle = '新增模版'
      this.params = {
        id: undefined,
        title: undefined,
        titlePicUrl: undefined,
        content: '',
        pictureUrl: undefined,
        linkUrl: undefined,
        informType: undefined, // 模版类型
        pushObject: undefined, // 推送对象
        isPopUp: undefined,
        intro: undefined, // 简介
      }
      this.contentImgList = []
      this.titleImgList = []
    },
    /** 改变标题图片 */
    handleChangeByTitleImg(list) {
      this.titleImgList = list
    },
    /** 改变内容图片 */
    handleChangeByContentImg(list) {
      this.contentImgList = list
    }
  },
};
</script>

<style lang="scss" scoped>
</style>